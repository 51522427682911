<template>
    <div class="max-box">
        <!-- 头部搜索、操作 -->
        <div class="header-box">
            <div class="header-left">
                <span>报警</span>
            </div>
            <!-- <a-input-search
              placeholder="输入报警名称"
              @search="onSearch"
              allow-clear
              class="search-box"
            >
              <a-button slot="enterButton" icon="search"></a-button>
            </a-input-search> -->
            <!--<a-button type="primary" class="add-btn" @click="openTopDrawer"
              >报警通知设置</a-button
            >-->
        </div>
        <!-- 内容区 -->
        <div class="content-box">
            <!-- 标签页 -->
            <div class="tarbar-box">
                <div class="tabs">
                    <div
                            class="tab-one"
                            v-for="tab in tabList"
                            :class="activeTab == tab.value ? 'active-tab' : ''"
                            @click="changeTab(tab.value)"
                    >
                        <span></span>
                        <div>{{ tab.label }}</div>
                    </div>
                    <div
                            class="tab-border"
                            @click="drawerVisible"
                            :class="visible ? 'openclose' : ''"
                    >
                        {{ visible ? "收起筛选" : "展开筛选" }}
                        <a-icon type="caret-down"/>
                    </div>
                </div>
                <div class="right-box">
                    <a-icon type="vertical-align-bottom" title="导出" class="icon-left"/>
                    <a-icon
                            type="sync"
                            class="icon-left"
                            @click="getTableList"
                            title="刷新"
                    />
                    <!-- 下拉菜单 -->
                    <myDrop
                            placement="bottomLeft"
                            :defaultFlag="defaultFlag"
                            :dropdownList="dropdownList"
                            @chooseItem="chooseItem"
                    ></myDrop>
                </div>
            </div>
            <!-- 表格内容 -->
            <div
                    style="position: relative; z-index: 2; overflow: hidden"
                    class="table-box"
                    ref="yHeight"
            >
                <!-- 表格 -->
                <myTable
                        :columns="columns"
                        :dataSource="tableData"
                        :total="total"
                        @changePagination="getTableList"
                        :scroll="{ x: 200, y: yHeight }"
                >
                    <template slot="alarmTit" slot-scope="slotData">
                        <div @click="toDetail(slotData.scope)" class="name-box">
                            {{ slotData.scope.alarmTitle }}
                        </div>
                    </template>
                    <template #statusSlot="slotData">
                        {{ slotData.scope.status == 1 ? "发生中" : "已解决" }}
                    </template>
                    <!-- <template #level="slotData">
                      <span>{{ getLavelName(slotData.scope.alarmLevel) }}</span>
                    </template> -->
                    <template slot="operation" slot-scope="slotData">
                        <div class="operation-box">
                            <a-icon type="file-add" title="提交工单"/>
                            <a-icon type="solution" class="icon-left" title="添加维保记录"/>
                        </div>
                    </template>
                </myTable>
                <!-- 筛选的抽屉 -->
                <a-drawer
                        placement="top"
                        :closable="false"
                        :visible="visible"
                        :get-container="false"
                        :wrap-style="{ position: 'absolute' }"
                        @close="onClose"
                        class="screen-drawer"
                        :bodyStyle="{
            background: 'fff',
            height: '100%',
            padding: '0',
          }"
                >
                    <div class="screen-box">
                        <div class="screen-content">
                            <div class="alarm-row">
                                <div class="row-title">等级：</div>
                                <div class="row-main">
                                    <div
                                            class="lavel"
                                            v-for="l in plainOptions"
                                            @click="chooseLavel(l.value)"
                                            :class="lavelChoose.includes(l.value) ? 'choose-lavel' : ''"
                                    >
                                        {{ l.label }}
                                    </div>
                                </div>
                            </div>
                            <div class="alarm-row">
                                <div class="row-title">报警开始时间</div>
                                <div class="row-main">
                                    <a-week-picker v-model="alarmTime"/>
                                </div>
                            </div>
                        </div>
                        <div class="btn-box">
                            <a-button @click="onClose">取消</a-button>
                            <a-button @click="resetSearch">重置</a-button>
                            <a-button type="primary" @click="searchConfirm">确定</a-button>
                        </div>
                    </div>
                </a-drawer>
            </div>
        </div>
        <!-- 报警设置抽屉 -->
        <a-drawer
                placement="right"
                :closable="false"
                :visible="settingDrawer"
                width="30%"
                wrapClassName="add-power"
                @close="closeSettingDrawer"
                :destroyOnClose="true"
                :headerStyle="{
        background: '#fff',
        padding: '0.2rem',
        flexGrow: 0,
      }"
                :bodyStyle="{
        padding: '0 0.2rem 0.2rem 0.2rem',
        background: 'fff',
        flexGrow: 1,
        overflowY: 'auto',
      }"
                :drawerStyle="{
        background: '#fff',
        display: 'flex',
        flexDirection: 'column',
      }"
        >
            <template #title>
                <div class="head-box">
                    <div class="drawer-title">
                        <span> 报警通知设置 </span>
                        <a-popover>
                            <template slot="content">
                                报警通知只用来设置产生报警后通过什么方式来通知到具体成员，不影响报警在报警列表中的展示
                            </template>
                            <a-icon type="question-circle" class="icon-tit"/>
                        </a-popover>
                    </div>
                    <div class="close-box" @click="closeSettingDrawer">
                        <a-icon type="close" class="close-icon"/>
                    </div>
                </div>
            </template>
            <div class="content-box">
                <div class="row-box">
                    <div class="row-title mb-6">报警频率：</div>
                    <div class="frequency-box">
                        <div
                                class="one-box"
                                v-for="item in frequencyList"
                                :class="chooseFrequency == item.value ? 'choose-box' : ''"
                                @click="changeFrequency(item.value)"
                        >
                            <div class="frequency">
                                <span>{{ item.frequency }}</span>
                                <div class="bell-box" v-if="chooseFrequency == item.value">
                                    <a-icon type="bell"/>
                                </div>
                            </div>
                            <div class="frequency-msg">{{ item.frequencyMsg }}</div>
                        </div>
                    </div>
                </div>
                <div class="row-box">
                    <div class="row-title mb-6">
                        每日通知上限：
                        <a-popover>
                            <template slot="content">
                                设置每日通知上限可以避免—些打扰
                            </template>
                            <a-icon type="question-circle" class="icon-tit"/>
                        </a-popover>
                    </div>
                    <div class="inform-limit">
                        <div
                                v-for="i in limitInform"
                                :class="i == chooseLimit ? 'choose-limit' : ''"
                                @click="changeLimit(i)"
                                class="normal-limit"
                        >
                            {{ i }}
                        </div>
                    </div>
                </div>
                <div class="row-box">
                    <div class="row-title mb-6">
                        通知方式：
                        <a-popover>
                            <template slot="content">
                                <div>站内通知：商家版Web内的通知方式</div>
                                <div>邮件：通过邮件通知，请确保接收人的邮件是有效的</div>
                                <div>短信：通过短信通知，请确保接收人的短信是有效的</div>
                                <div>
                                    APP推送：通过手机操作系统通知，请确保接收人已安装商家版APP
                                </div>
                            </template>
                            <a-icon type="question-circle" class="icon-tit"/>
                        </a-popover>
                    </div>
                    <a-checkbox-group v-model="informWay">
                        <a-checkbox
                                :value="i.value"
                                v-for="i in informWayList"
                                :disabled="i.disabled"
                        >{{ i.label }}
                        </a-checkbox>
                    </a-checkbox-group>
                </div>
                <div class="row-box">
                    <div class="row-title mb-6">通知接收人：</div>
                    <div class="member-box">
                        <template v-if="chooseUserList.length > 0">
                            <div class="member-msg" v-for="i in chooseUserList">
                                <div class="station-text">{{ i.name + i.id }}</div>
                                <a-icon
                                        type="close"
                                        class="close-icon"
                                        @click="deleteMember(i.id)"
                                />
                            </div>
                        </template>
                        <div class="add-btn" @click="$refs.MemberModal.openModal()">
                            <a-icon type="plus"/>
                            成员
                        </div>
                    </div>
                </div>
            </div>
            <div class="btns-box">
                <a-button @click="closeSettingDrawer">取消</a-button>
                <a-button type="primary" class="ml-16" @click="saveInform"
                >保存
                </a-button
                >
            </div>
        </a-drawer>
        <!-- 详情抽屉 -->
        <a-drawer
                placement="right"
                :closable="false"
                :visible="detailDrawer"
                width="83%"
                wrapClassName="detail-drawer"
                @close="closeDetailDrawer"
                :destroyOnClose="true"
                :headerStyle="{
        background: '#fff',
        padding: '0.2rem',
        flexGrow: 0,
      }"
                :bodyStyle="{
        padding: '0.3rem 0 0.2rem 0',
        background: 'fff',
        flexGrow: 1,
        overflowY: 'auto',
      }"
                :drawerStyle="{
        background: '#EEEEEF',
        display: 'flex',
        flexDirection: 'column',
      }"
        >
            <template #title>
                <div class="head-box">
                    <div class="name-box">
                        <div class="alarm-status-box">
                            <div
                                    class="alarm-status"
                                    :class="currentData?.status == 0 ? 'normal' : ''"
                            >
                                {{ currentData?.status == 0 ? "正常" : "警告" }}
                            </div>
                            <span>{{ currentData?.alarmTitle }}</span>
                        </div>

                        <div class="operation-right">
                            <a-icon
                                    type="sync"
                                    title="刷新"
                                    class="mr_16"
                                    @click="getDetail"
                            />
                            <div class="close-box" @click="closeDetailDrawer">
                                <a-icon type="close" class="close-icon"/>
                            </div>
                        </div>
                    </div>
                    <div class="status-box">
                        <div v-for="item in detailHead" class="one-msg">
                            <span>{{ item.title }}</span>
                            <div v-if="item.prop == 'status'">
                                {{ item.value == 0 ? "已解决" : "发生中" }}
                            </div>
                            <div v-else>{{ item.value }}</div>
                        </div>
                    </div>
                </div>
            </template>
            <div class="content-box">
                <div class="solution-box">
                    <div class="title">解决方案</div>
                    <div>--</div>
                </div>
                <div class="compare-box">
                    <div class="head">
                        <div class="head-left">
                            <span>数据对比</span>
                            <a-popover>
                                <template slot="content">
                                    <div>
                                        通过数据对比，可以看到该报警所属设备历史数据与报警历史分布之间的对比，
                                    </div>
                                    <div>从而更直观地看到报警对设备的影响。</div>
                                </template>
                                <a-icon type="question-circle"/>
                            </a-popover>
                        </div>
                        <!--<div>参数选择</div> -->
                    </div>
                    <div id="alarm-echarts"></div>
                    <div></div>
                </div>
            </div>
        </a-drawer>
        <MemberModal
                ref="MemberModal"
                @onSubmit="changeMember"
                :userList.sync="userEntities"
        ></MemberModal>
    </div>
</template>
<script>
    import {
        queryAlarmList,
        alarmNotice,
        queryalarmNotice,
        alarmHistoryData,
    } from "@/api/alarm.js";
    import moment from "moment";
    import {alarmDetailEcharts} from "./config/alarmEchart";
    import {debound} from "@/utils/index.js";
    import {dcAorVEcharts} from "./config/dcEcharts";

    export default {
        data() {
            return {
                activeTab: 1,
                tabList: [
                    {
                        value: "2",
                        label: "全部",
                    },
                    {
                        value: "1",
                        label: "发生中",
                    },
                    {
                        value: "0",
                        label: "已恢复",
                    },
                ],
                columns: [
                    {
                        title: "序号",
                        dataIndex: "index",
                        width: 70,
                        customRender: (text, record, index) => {
                            return index + 1;
                        },
                    },
                    {
                        title: "报警名称",
                        dataIndex: "alarmTitle",
                        scopedSlots: {
                            customRender: "alarmTit",
                        },
                    },
                    {
                        title: "状态",
                        dataIndex: "status",
                        scopedSlots: {
                            customRender: "statusSlot",
                        },
                    },
                    {
                        title: "电站",
                        dataIndex: "stationName",
                    },
	                {
		                title: "安装位置",
		                dataIndex: "childSystem",
	                },
	                {
		                title: "矩阵",
		                dataIndex: "matrix",
	                },
                    {
                        title: "设备",
                        dataIndex: "deviceSn",
                    },
                    {
                        title: "报警开始时间",
                        dataIndex: "createTime",
                    },
                ],
                tableData: [],
                total: 0,
                // 筛选抽屉
                visible: false,
                moment,
                defaultFlag: "0",
                dropdownList: [
                    {
                        label: "关闭",
                        value: "0",
                    },
                    {
                        label: "3分钟",
                        value: "3",
                    },
                    {
                        label: "6分钟",
                        value: "6",
                    },
                    {
                        label: "9分钟",
                        value: "9",
                    },
                ],
                // 报警设置抽屉
                settingDrawer: false,
                getTabName: "",
                detailHead: [
                    {
                        title: "状态",
                        value: "发生中",
                        prop: "status",
                    },
                    {
                        title: "电站",
                        value: "--",
                        prop: "stationName",
                        otherClass: true,
                    },
                    {
                        title: "设备",
                        value: "--",
                        prop: "deviceSn",
                        otherClass: true,
                    },
                    {
                        title: "报警开始时间",
                        value: "--",
                        prop: "createTime",
                    },
                ],
                hourTime: moment(),
                timeLine: [
                    {
                        value: "00:00",
                        left: "0",
                    },
                    {
                        value: "03:00",
                        left: "12.5%",
                    },
                    {
                        value: "06:00",
                        left: "25%",
                    },
                    {
                        value: "09:00",
                        left: "37.5%",
                    },
                    {
                        value: "12:00",
                        left: "50%",
                    },
                    {
                        value: "15:00",
                        left: "62.5%",
                    },
                    {
                        value: "18:00",
                        left: "75%",
                    },
                    {
                        value: "21:00",
                        left: "87.5%",
                    },
                    {
                        value: "24:00",
                        left: "100%",
                    },
                ],
                informRadio: 1,
                alarmInform: ["1", "2", "3"],
                plainOptions: [
                    {
                        value: "1",
                        label: "提示",
                    },
                    {
                        value: "2",
                        label: "警告",
                    },
                    {
                        value: "3",
                        label: "故障",
                    },
                ],

                //频率
                frequencyList: [
                    {
                        value: 1,
                        frequency: "低频",
                        frequencyMsg: "持续报警60分钟后发出通知",
                    },
                    {
                        value: 2,
                        frequency: "中频",
                        frequencyMsg: "持续报警15分钟后发出通知",
                    },
                    {
                        value: 3,
                        frequency: "高频",
                        frequencyMsg: "报警发生后立即发出通知",
                    },
                ],
                chooseFrequency: 1,

                // 通知上限
                limitInform: [2, 10, 20, 50],
                chooseLimit: 10,

                // 通知方式
                informWay: ["1"],
                informWayList: [
                    {
                        value: "1",
                        label: "站内通知",
                        disabled: true,
                    },
                    {
                        value: "2",
                        label: "邮件(剩余500条)",
                    },
                    {
                        value: "3",
                        label: "短信(剩余250条)",
                    },
                    {
                        value: "4",
                        label: "APP推送",
                    },
                ],

                // 筛选
                lavelChoose: [],
                alarmTime: null,
                userEntities: [],
                chooseUserList: [],

                // 详情
                detailDrawer: false,

                yHeight:
                    document.documentElement.clientHeight -
                    document.documentElement.clientHeight / 4.9,
                currentData: null,
                chartInstance: null,
            };
        },
        methods: {
            onSearch() {
            },
            openTopDrawer() {
                this.settingDrawer = true;
                this.getQueryalarmNotice();
            },
            changeTab(value) {
                this.activeTab = value;

                this.getTableList();
            },
            onClose() {
                this.visible = false;
            },
            // 获取表格数据
            getTableList(pageMsg) {
                let alarm = {
                    status: this.activeTab == 2 ? null : this.activeTab,
                    alarmLevel: this.lavelChoose,
                    startTime: this.alarmTime
                        ? moment(this.alarmTime).day(1).format("YYYY-MM-DD")
                        : null,
                    endTime: this.alarmTime
                        ? moment(this.alarmTime).day(7).format("YYYY-MM-DD")
                        : null,
                };
                let flag = false;
                for (let k in alarm) {
                    if (alarm[k]) {
                        if (k == "alarmLevel" && alarm[k].length != 0) {
                            flag = true;
                        }
                    }
                }

                let requestPage = {
                    page: 1,
                    limit: 50,
                };
                if (pageMsg?.page) {
                    requestPage.page = pageMsg.page;
                    requestPage.limit = pageMsg.pageSize;
                }
                queryAlarmList({...requestPage, alarm: flag ? alarm : {}}).then(
                    (res) => {
                        console.log(res);
                        this.tableData = res.data;
                        this.total = res.count;
                    }
                );
            },

            drawerVisible() {
                this.visible = !this.visible;
            },
            chooseItem(item) {
                this.defaultFlag = item;
            },

            closeSettingDrawer() {
                this.settingDrawer = false;
                this.resetInfrom();
            },
            // 查询警告详情
            getDetail() {
            },
            dealDay(num) {
                let time = moment(this.hourTime);
                this.hourTime = num < 0 ? time.subtract(1, "d") : time.add(1, "d");
            },

            changeFrequency(value) {
                this.chooseFrequency = value;
            },
            changeLimit(i) {
                this.chooseLimit = i;
            },

            // 等级选择
            chooseLavel(value) {
                let findIndex = this.lavelChoose.findIndex((item) => item == value);
                if (findIndex == -1) {
                    this.lavelChoose.push(value);
                } else {
                    this.lavelChoose.splice(findIndex, 1);
                }
            },
            searchConfirm() {
                this.getTableList();
                this.visible = false;
            },
            instance() {
                const comparebox = document.getElementById("alarm-echarts");

                this.chartInstance = this.echarts.init(comparebox);
            },
            resetSearch() {
                this.alarmTime = null;
                this.lavelChoose = [];
                this.getTableList();
            },
            deleteMember(id) {
                this.userEntities = this.userEntities.filter((item) => {
                    return item != id;
                });
                this.chooseUserList = this.chooseUserList.filter((item) => {
                    return item.id != id;
                });
            },
            changeMember(user) {
                this.chooseUserList = user;
            },
            saveInform() {
                if (this.chooseUserList.length > 0) {
                    let requestData = {
                        alarmRate: this.chooseFrequency,
                        noticeLimit: this.chooseLimit,
                        type: this.informWay.join(","),
                        userEntities: this.chooseUserList.map((item) => {
                            return {id: item.id, name: item.name};
                        }),
                    };
                    alarmNotice(requestData).then((res) => {
                        this.$message.success("通知设置成功");
                    });
                } else {
                    this.$message.warning("请选择通知接收人");
                }
            },
            resetInfrom() {
                this.chooseFrequency = 1;
                this.chooseLimit = 10;
                this.informWay = ["1"];
                this.chooseUserList = [];
            },
            getQueryalarmNotice() {
                queryalarmNotice({}).then((res) => {
                    console.log(res);
                    if (res.data) {
                        this.chooseFrequency = res.data.alarmRate;
                        this.chooseLimit = res.data.noticeLimit;
                        this.informWay = res.data.type.split(",");
                    }
                });
            },
            getLavelName(l) {
                let lavel = this.plainOptions.find((item) => {
                    return item.value == l;
                });
                if (lavel) {
                    return lavel.label;
                } else {
                    return "--";
                }
            },

            // 详情
            closeDetailDrawer() {
                this.detailDrawer = false;
            },
            toDetail(row) {
                this.detailDrawer = true;
                this.currentData = row;
                this.detailHead.forEach((item) => {
                    item.value = row[item.prop];
                });
                this.getHistoryData();
                console.log(row, "---row");
                // this.$nextTick(() => {
                //   this.initEcharts();
                // });
            },
            getHistoryData() {
                alarmHistoryData({id: this.currentData.id}).then((res) => {
                    this.dealDcData(res.data);
                });
            },
            dealDcData(data) {
                if (Object.keys(data).length != 0) {
                    let minTime, maxTime;
                    let legend = [];
                    let yData = [];
                    let flag = 0;
                    let legendText = ["直流电流", "直流电压"];
                    for (let k in data) {
                        data[k].forEach((dc) => {
                            if (flag == 0) {
                                legend.push(
                                    ...[legendText[0] + dc.title, legendText[1] + dc.title]
                                );
                                yData.push(
                                    ...[
                                        {
                                            name: legendText[0] + dc.title,
                                            type: "line",
                                            data: [],
                                            // areaStyle: {},
                                            symbol: "none",
                                            smooth: true,
                                            yAxisIndex: 0,
                                        },
                                        {
                                            name: legendText[1] + dc.title,
                                            type: "line",
                                            yAxisIndex: 1,
                                            data: [],
                                            smooth: true,
                                            // areaStyle: {},
                                            symbol: "none",
                                        },
                                    ]
                                );
                            }

                            yData.forEach((y) => {
                                if (y.name == legendText[0] + dc.title) {
                                    y.data.push([dc.createTime, dc.voltage]);
                                }
                                if (y.name == legendText[1] + dc.title) {
                                    y.data.push([dc.createTime, dc.current]);
                                }
                            });
                        });
                        flag++;
                    }

                    this.instance();
                    // this.chartInstance.clear();
                    yData.forEach((item) => {
                        item.data.sort((a, b) => {
                            return moment(a[0]).valueOf() - moment(b[0]).valueOf();
                        });
                    });
                    minTime = moment(yData[0].data[0][0]).valueOf();
                    let index = yData[0].data.length - 1;
                    maxTime = moment(yData[0].data[index][0]).valueOf();
                    console.log(yData[0].data[index][0], "------legend-----", yData[0]);
                    this.chartInstance.setOption(
                        dcAorVEcharts({min: minTime, max: maxTime}, legend, yData)
                    );
                    this.$nextTick(() => {
                        this.chartInstance.resize();
                    });
                }
            },
            initEcharts() {
                const alarmDom = document.getElementById("alarm-echarts");
                const alarmEchart = this.echarts.init(alarmDom, null, {
                    devicePixelRatio: window.devicePixelRatio,
                });
                alarmEchart.setOption(alarmDetailEcharts());
                setTimeout(() => {
                }, 0);
                this.$nextTick(() => {
                    alarmEchart.resize();
                });
            },
            watchResize() {
                this.yHeight = this.$refs["yHeight"].clientHeight - 90;
                window.addEventListener("resize", this.screenResize);
            },
            screenResize: debound(function () {
                // document.documentElement.clientHeight;
                this.yHeight = this.$refs["yHeight"].clientHeight - 90;
            }, 200),
        },
        mounted() {
            this.getTableList();
            this.watchResize();
        },
        beforeDestroy() {
            window.removeEventListener("resize", this.screenResizec);
        },
        computed: {},
    };
</script>
<style lang="less" scoped>
    .max-box {
        width: 100%;
        padding-top: 16px;
        padding-right: 20px;
        box-sizing: border-box;
        height: 100%;
        display: flex;
        flex-direction: column;

        .header-box {
            display: flex;
            justify-content: space-between;
            align-items: center;
            // padding-right: 20px;
            margin-bottom: 16px;
            font-size: 16px;

            .header-left {
                span {
                    font-size: 18px;
                    color: #252b3a;
                }

                i {
                    font-size: 20px;
                }
            }

            .search-box {
                width: 240px;
                height: 32px;

                /deep/ .ant-input {
                    font-size: 12px;
                }
            }

            .add-btn {
                font-size: 12px;
            }
        }

        .content-box {
            background: #fff;
            width: 100%;
            box-sizing: border-box;
            flex-grow: 1;
            display: flex;
            flex-direction: column;
            // tabs
            .tarbar-box {
                padding-bottom: 12px;
                position: relative;
                padding-right: 16px;
                width: 100%;
                border-bottom: 1px solid rgba(0, 0, 0, 0.1);
                display: flex;
                justify-content: space-between;
                align-items: center;

                .tabs {
                    display: flex;
                    padding-left: 16px;
                    align-items: center;
                    box-sizing: border-box;

                    .tab-one {
                        padding: 10px 0 8px 0;
                        margin-right: 24px;
                        font-size: 12px;
                        cursor: pointer;
                        color: #515561;
                        box-sizing: border-box;
                    }

                    .tab-border {
                        border-left: 1px solid rgba(0, 0, 0, 0.1);
                        font-size: 12px;
                        cursor: pointer;
                        color: #515561;
                        box-sizing: border-box;
                        padding-left: 16px;
                    }

                    .openclose {
                        color: #048fff;

                        i {
                            transform: rotate(180deg);
                        }
                    }

                    .open-choose {
                        margin-top: 3px;
                        cursor: pointer;
                        padding-left: 16px;
                        border-left: 1px solid rgba(0, 0, 0, 0.1);

                        &:hover {
                            color: #048fff;
                        }

                        span {
                            margin-right: 1px;
                        }

                        transition: all 1s;
                    }

                    .active-choose {
                        color: #048fff;

                        .icon-down {
                            transform: rotate(180deg);
                        }
                    }

                    .active-tab {
                        color: #048fff;
                        border-bottom: 2px solid #048fff;
                    }
                }

                .right-box {
                    font-size: 16px;
                    display: flex;
                    align-items: center;

                    i {
                        cursor: pointer;
                    }

                    .icon-left {
                        margin-right: 8px;
                        transition: all 0.4s;

                        &:hover {
                            transform: rotate(90deg);
                        }
                    }
                }
            }

            .table-box {
                min-height: 220px;
                flex-grow: 1;

                .name-box {
                    cursor: pointer;
                }
            }

            // 操作样式
            .operation-box {
                width: 100%;
                padding-left: 15px;
                font-size: 16px;

                i {
                    cursor: pointer;
                    transition: all 0.5s;

                    &:hover {
                        color: #048fff;
                    }
                }

                .icon-left {
                    margin-left: 30px;
                }
            }
        }
    }

    /deep/ .screen-drawer {
        .ant-drawer-content-wrapper {
            height: 200px !important;
        }

        .screen-box {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;

            .screen-content {
                padding-top: 12px;
                flex-grow: 0;

                .alarm-row {
                    display: flex;
                    color: #515561;
                    border-bottom: 1px solid #d9d9d9;
                    font-size: 12px;

                    .row-title {
                        padding: 16px;
                        padding-right: 4px;
                        background: #f0f0f1;
                        width: 112px;
                    }

                    .row-main {
                        padding: 16px;
                        display: flex;

                        .lavel {
                            margin-right: 24px;
                            cursor: pointer;

                            &:hover {
                                color: #048fff;
                            }
                        }

                        .choose-lavel {
                            color: #048fff;
                        }
                    }
                }
            }

            .btn-box {
                flex-grow: 1;
                padding-left: 128px;
                display: flex;
                align-items: center;

                .ant-btn {
                    font-size: 12px;
                    height: 32px;
                    margin-right: 16px;
                }
            }
        }
    }

    // 报警设置抽屉内容
    .add-power {
        .head-box {
            height: max-content;
            display: flex;
            justify-content: space-between;

            .drawer-title {
                span {
                    font-size: 16px;
                    margin-right: 8px;
                }

                .icon-tit {
                    cursor: pointer;

                    &:hover {
                        color: #048fff;
                    }
                }
            }

            .close-box {
                background: #ff7875;
                cursor: pointer;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 28px;
                height: 28px;
                font-size: 16px;
                color: #fff;
                border-radius: 50%;

                &:hover {
                    i {
                        transform: rotate(90deg);
                    }
                }

                i {
                    transition: all 0.3s;
                }
            }
        }

        .content-box {
            .row-box {
                padding: 20px 0;
                border-bottom: 1px dashed rgba(0, 0, 0, 0.1);

                .row-title {
                    font-size: 12px;
                }

                .mb-6 {
                    margin-bottom: 6px;
                }

                .frequency-box {
                    display: flex;
                    flex-wrap: wrap;

                    .one-box {
                        width: 222px;
                        padding: 16px;
                        margin-right: 8px;
                        margin-bottom: 8px;
                        cursor: pointer;
                        border: 1px solid #e3e3e7;

                        .frequency {
                            display: flex;
                            justify-content: space-between;
                            font-size: 14px;

                            .bell-box {
                                width: 24px;
                                height: 24px;
                                background: #048fff1a;
                                border-radius: 50%;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                font-size: 12px;
                                color: #048fff;
                            }
                        }

                        .frequency-msg {
                            font-size: 12px;
                            color: #95929c;
                        }

                        &:hover {
                            color: #048fff;
                            border-color: #048fff;
                        }
                    }

                    .choose-box {
                        color: #048fff;
                        border-color: #048fff;

                        span {
                            color: #048fff;
                        }
                    }
                }

                .inform-limit {
                    display: flex;
                    width: 100%;

                    .normal-limit {
                        padding: 4px 16px;
                        margin-right: 16px;
                        cursor: pointer;
                        border: 1px solid #e3e3e7;
                        font-size: 12px;

                        &:hover {
                            color: #048fff;
                            border-color: #048fff;
                        }
                    }

                    .choose-limit {
                        color: #048fff;
                        border-color: #048fff;
                    }
                }

                .member-box {
                    padding: 4px 32px 4px 12px;
                    border: 1px solid #d9d9d9;
                    border-radius: 4px;
                    display: flex;
                    align-items: center;
                    flex-wrap: wrap;
                    max-height: 60px;
                    overflow-y: auto;

                    .member-msg {
                        height: 22px;
                        padding: 0 8px;
                        border-radius: 2px;
                        background-color: rgba(0, 0, 0, 0.04);
                        display: flex;
                        align-items: center;
                        margin-right: 16px;
                        margin-bottom: 4px;

                        .station-text {
                            max-width: 480px;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            font-size: 12px;
                        }

                        .close-icon {
                            font-size: 12px;
                            margin-left: 12px;
                            cursor: pointer;

                            &:hover {
                                color: #ff7875;
                            }
                        }
                    }

                    .add-btn {
                        font-size: 12px;
                        color: #048fff;
                        display: flex;
                        align-items: center;
                        cursor: pointer;
                    }
                }
            }
        }

        .btns-box {
            display: flex;
            justify-content: center;
            margin-top: 30px;

            /deep/ .ant-btn {
                font-size: 12px;
            }
        }
    }

    // 详情
    .detail-drawer {
        // transform: translate(0) !important;
        .head-box {
            .name-box {
                display: flex;
                justify-content: space-between;

                .alarm-status-box {
                    display: flex;
                    align-items: center;

                    div {
                        width: 64px;
                        height: 24px;
                        background: #ffb450;
                        font-size: 12px;
                        color: #fff;
                        text-align: center;
                        line-height: 24px;
                    }

                    .normal {
                        background: #41d068;
                    }

                    span {
                        font-size: 22px;
                        font-weight: 700;
                        color: #646464;
                        margin-left: 8px;
                    }
                }

                .operation-right {
                    display: flex;
                    align-items: center;
                    font-size: 16px;
                    color: rgba(0, 0, 0, 0.45);

                    .mr_16 {
                        margin-right: 16px;
                        transition: all 0.5s;
                        cursor: pointer;

                        &:hover {
                            color: #048fff;
                        }
                    }

                    .close-box {
                        background: #ff7875;
                        cursor: pointer;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 28px;
                        height: 28px;
                        font-size: 16px;
                        color: #fff;
                        border-radius: 50%;

                        &:hover {
                            i {
                                transform: rotate(90deg);
                            }
                        }

                        i {
                            transition: all 0.3s;
                        }
                    }
                }
            }

            .status-box {
                display: grid;
                // justify-content: space-between;
                margin-top: 24px;
                grid-template-columns: repeat(5, 1fr);

                .one-msg {
                    display: flex;
                    flex-direction: column;
                    font-size: 12px;
                    padding-left: 8px;
                    color: #515561;

                    span {
                        color: #00000073;
                        margin-bottom: 4px;
                    }
                }
            }
        }

        .drawer-tab-box {
            padding: 0 8px;
            margin-top: 16px;

            .all-tabs {
                display: flex;

                span {
                    padding: 12px 0 8px;
                    margin-right: 24px;
                    font-size: 12px;
                    color: #00000073;
                    cursor: pointer;
                }

                .active-drawer-tab {
                    color: #048fff;
                    border-bottom: 2px solid #048fff;
                    font-weight: 700;
                }
            }
        }

        .content-box {
            padding: 16px;
            box-sizing: border-box;
            height: 100%;
            overflow-y: auto;

            .solution-box {
                padding: 16px;
                border: 1px solid rgba(37, 43, 58, 0.1);
                border-radius: 4px;
                background: #fff;
                box-shadow: 0 2px 16px rgba(0, 0, 0, 0.05);

                .title {
                    font-size: 14px;
                    color: #252b3a;
                    margin-bottom: 16px;
                }

                .msg {
                    color: #515561;
                    font-size: 12px;
                }
            }

            .compare-box {
                padding: 16px;
                border: 1px solid rgba(37, 43, 58, 0.1);
                border-radius: 4px;
                background: #fff;
                box-shadow: 0 2px 16px rgba(0, 0, 0, 0.05);
                margin-top: 16px;

                .head {
                    display: flex;
                    justify-content: space-between;
                    margin-bottom: 16px;

                    .head-left {
                        font-size: 14px;
                        color: #252b3a;

                        span {
                            margin-right: 8px;
                        }

                        i {
                            font-size: 15px;
                            transition: all 0.3s;
                            cursor: pointer;

                            &:hover {
                                color: #048fff;
                            }
                        }
                    }
                }

                #alarm-echarts {
                    width: 100%;
                    height: 336px;
                }
            }
        }
    }
</style>
